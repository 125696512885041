import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import Recon from '../images/Carbon2.jpg'
import '../styles.scss'
import './teamtomcat.scss'
export default function TomcatTeam() {
    return (
        <Layout>
            <div className="team-tomcat-header">
                <h4>Team <span className="blue">Tomcat</span></h4>
            </div>
            <PaddedLayout>
                <div className="page-wrapper">
                    <div className="team-wrapper">
                        <div className="team-member">
                            <img src={Recon} alt="" />
                            <sub className="name">John Smith</sub>
                            <sub className="title">CEO</sub>
                        </div>
                        <div className="team-member">
                            <img src={Recon} alt="" />
                            <sub className="name">John Smith</sub>
                            <sub className="title">CEO</sub>
                        </div>
                        <div className="team-member">
                            <img src={Recon} alt="" />
                            <sub className="name">John Smith</sub>
                            <sub className="title">CEO</sub>
                        </div>
                        <div className="team-member">
                            <img src={Recon} alt="" />
                            <sub className="name">John Smith</sub>
                            <sub className="title">CEO</sub>
                        </div>
                    </div>
                </div>
            </PaddedLayout>
        </Layout>
    )
}


